<template>
  <div class="stream-overlay">
    <div class="container stream-overlay-wrapper">
      <div class="preview-overlay">
        <PreviewOverlay
          :scale="transformScale"
          :image-src="activeImg"
          :active="activeLayer"
          :align="overlay.align"
          :video-key="videoKey"
          :media-width="parseInt(overlay.carousel.width)"
          :media-height="parseInt(overlay.carousel.height)"
          :media-x="parseInt(overlay.carousel.x)"
          :media-y="parseInt(overlay.carousel.y)"
          :screen-width="parseInt(overlay.screenWidth)"
          :screen-height="parseInt(overlay.screenHeight)"
          @removeMedia="overlay.carousel.imageArr = []"
          @onResize="setSize"
          @newPos="catchNewPos"
          @activeLayer="setActiveLayer"
        >
          <div class="items-center stream-overlay__actions">
            <div class="layer__tools-list">
              <label class="flex items-center mr-8 w-full">
                <span class="mr-4 text-blue-default input__label">Timer</span>

                <InputText
                  v-if="
                    overlay.carousel.imageArr.length && overlay.carousel.imageArr[activeImageIndex].type !== 'video'
                  "
                  unit="ms"
                  align="right"
                  direction="horizontal"
                  :width="120"
                  :value="overlay.carousel.duration"
                  class="mr-8 text-blue-default"
                  @onChange="updateTimer"
                />

                <div
                  v-else-if="
                    overlay.carousel.imageArr.length && overlay.carousel.imageArr[activeImageIndex].type === 'video'
                  "
                  type="number"
                  disabled
                  title="You can't edit the duration of a video"
                  class="mr-8 text-blue-default input__field input_video_duration"
                  min="0"
                  :unit="'ms'"
                >
                  {{ overlay.carousel.imageArr[activeImageIndex].duration.toFixed(0) }}
                </div>
              </label>

              <label class="flex items-center mr-8 w-full">
                <span class="mr-4 text-blue-default input__label">Width</span>

                <InputText
                  unit="px"
                  :value="overlay.carousel.width"
                  align="right"
                  :direction="'horizontal'"
                  :max="overlay.screenWidth"
                  :width="80"
                  class="mr-8 text-blue-default"
                  @onChange="updateWidth"
                />
              </label>

              <label class="flex items-center mr-8 w-full">
                <span class="mr-4 text-blue-default input__label">Height</span>

                <InputText
                  unit="px"
                  :value="overlay.carousel.height"
                  align="right"
                  direction="horizontal"
                  :max="overlay.screenHeight"
                  :width="80"
                  class="mr-8 text-blue-default"
                  style="color: red !important"
                  @onChange="updateHeight"
                />
              </label>
            </div>

            <Button cta="green" class="ml-auto" @clicked="saveOverlay"> Done </Button>
          </div>

          <label v-show="!overlay.carousel.imageArr.length" class="input input__file">
            <Icon icon="add-image" class="mb-4" :width="32" :height="32" />
            <span class="input__label">Click to add media</span>
            <input
              ref="imageInput"
              required
              type="file"
              accept="image/*,video/*"
              class="input__field"
              @change="onFileChange"
            />
          </label>

          <template v-if="loading">
            <span class="spinner input input__file" />
          </template>
        </PreviewOverlay>
      </div>
    </div>

    <Prompt
      v-if="showSavePrompt"
      :title="!data ? 'Stream overlay has been added' : 'Stream overlay has been updated'"
      :text="'You can now close this window'"
      :cta-text="'Yes, close'"
      :cancel-text="'No, not now'"
      icon="success"
      @onTrue="$emit('closeModal')"
      @onFalse="showSavePrompt = false"
    />
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapMutations } from "vuex";
import getBlobDuration from "get-blob-duration";
import { Icon } from "cavea-design-system";
import Image from "@/lib/helpers/image";
import log from "@/lib/log";

export default {
  name: "StreamOverlay",

  metaInfo: {
    title: "Overlay Builder",
  },

  components: {
    Icon,
    PreviewOverlay: () => import("@/components/overlay-tool/PreviewOverlay"),
    Button: () => import("@/components/Button"),
    InputText: () => import("@/components/inputs/InputText"),
    Prompt: () => import("@/components/Prompt"),
  },

  props: {
    data: {
      type: Object,
      default: null,
    },
    newCampaign: Boolean,
  },

  data() {
    return {
      maxHeight: 0,
      loading: false,

      showPreview: false,
      showSavePrompt: false,
      videoKey: 0,
      activeLayer: false,
      tempFile: { data: null, type: null, duration_ms: 0 },
      newImage: {
        title: "",
        brandId: "",
        imageSrc: "",
      },
      activeImageIndex: 0,
      transformScale: 1,

      overlay: {
        screenWidth: 1920,
        screenHeight: 1080,
        align: {
          h: "flex-start",
          v: "flex-start",
        },
        carousel: {
          duration: 5000,
          width: 400,
          height: 300,
          x: 0,
          y: 0,
          activeSlide: 0,
          imageArr: [],
        },
      },
    };
  },

  computed: {
    ...mapGetters(["getOrganization", "getCampaign", "getBrands"]),

    activeImg() {
      if (this.overlay?.carousel?.imageArr?.length) {
        return this.overlay.carousel.imageArr[this.activeImageIndex];
      }
      return null;
    },
  },

  watch: {
    overlay: {
      deep: true,

      handler() {
        this.setNewOverlay(this.overlay);
      },
    },

    activeImageIndex() {
      this.videoKey = parseInt(Math.random() * 1000, 10);
    },
  },

  mounted() {
    // Set top nav offset
    const header = document.querySelector("#header");
    const style = getComputedStyle(header);
    const height = parseInt(style.marginTop, 10);
    const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    this.maxHeight = windowHeight - height - 5;

    // Set brandId & brandTitle
    this.newImage.brandId = this.getCampaign.brand.id;
    this.newImage.title = this.getCampaign.brand.title;

    // Set overlay
    this.overlay = this.getCampaign.tools.overlay;
  },

  methods: {
    ...mapMutations(["setNewOverlay"]),

    /**
     * @summary Save state of overlay
     */
    saveOverlay() {
      this.showSavePrompt = true;

      this.setNewOverlay(this.overlay);

      this.$router.push("/create/campaign/content");
    },

    /**
     * @summary update media width (input field)
     * @param {number|string} value - width
     */
    updateWidth(value) {
      this.overlay.carousel.width = value;
    },

    /**
     * @summary update media height (input field)
     * @param {number|string} value - height
     */
    updateHeight(value) {
      this.overlay.carousel.height = value;
    },

    /**
     * @summary update media shown duration
     * @param {number|string} value - duration in ms
     */
    updateTimer(value) {
      this.overlay.carousel.imageArr[this.activeImageIndex].duration = parseFloat(value);

      this.overlay.carousel.duration = parseFloat(value);
    },

    /**
     * @summary update media width/height (media resize)
     * @param {number|string} value - width
     */
    setSize(sizes) {
      this.overlay.carousel.width = sizes.width * 2;
      this.overlay.carousel.height = sizes.height * 2;
    },

    /**
     * @summary set active carousel image
     * @param {number|string} value
     */
    setActiveLayer(value) {
      this.activeLayer = value;
    },

    /**
     * @param {event} e
     */
    closeModal(e) {
      if (e?.target?.classList?.contains("modal")) {
        this.showBrandModal = false;
      }
    },

    /**
     * @param {{ x: number, y: number }} obj - x, y pos
     */
    catchNewPos(obj) {
      this.overlay.carousel.x = obj.x * 2;
      this.overlay.carousel.y = obj.y * 2;
    },

    /**
     * @summary create new media src. works in conjunction with processImage()
     */
    async onFileChange() {
      this.loading = true;

      const input = this.$refs.imageInput;
      if (
        input?.files[0]?.type === "image/png" ||
        input?.files[0]?.type === "image/jpg" ||
        input?.files[0]?.type === "image/jpeg"
      ) {
        this.tempFile.data = await Image.resize(this.$refs.imageInput.files[0]);
        this.tempFile.type = "image";
      }

      if (input?.files[0]?.type?.includes("video")) {
        log("onfilechange tempfile", this.tempFile);
        // check file size max 2500kb if !true display modal "File is to big the maximum file must be 25mb" () => return
        const maxBytes = 26214400;
        const fileSize = input.files[0].size;

        if (fileSize > maxBytes) {
          alert("This file is too big, please try with another file or reduce its size. Max size is 25 MegaByte");
          return;
        }
        // initialzie form data for upload file on storage.cavea.io
        const formData = new FormData();
        window.URL = window.URL || window.webkitURL;
        const url = window.URL.createObjectURL(input.files[0]);
        formData.append("file", input.files[0]);
        const headers = { "Content-Type": "multipart/form-data" };

        await axios
          .post(`${process.env.VUE_APP_STORAGE_SERVICE}/upload-binary`, formData, { headers })
          .then((res) => {
            this.tempFile.data = res.data;
          })
          .catch((e) => {
            console.error("onFileChange e", e);
          })
          .then(() => {
            this.loading = false;
          });

        this.tempFile.duration_ms = (await getBlobDuration(url)) * 1000;
        this.tempFile.type = "video";
      }

      this.processImage();
    },

    /**
     * @summary create new media src. works in conjunction with onFileChange()
     */
    async processImage() {
      log("processImage tempfile", this.tempFile);

      if (this.tempFile?.type === "video") {
        this.newImage.type = "video";
        this.newImage.duration = this.tempFile.duration_ms;
        this.newImage.imageSrc = this.tempFile.data;

        this.overlay.carousel.imageArr.push(this.newImage);
        this.overlay.carousel.duration = this.tempFile.duration_ms;
        this.activeImageIndex = this.overlay.carousel.imageArr.length - 1;

        this.newImage = {
          title: this.getCampaign.brand.title,
          brandId: this.getCampaign.brand.id,
          imageSrc: "",
          type: "",
          duration: "",
        };

        this.showPreview = true;
        return;
      }

      this.tempFile = { data: null, type: null, duration_ms: 0 };

      const { imageInput } = this.$refs;

      try {
        const file = imageInput.files[0];

        if (file) {
          this.newImage.imageSrc = await Image.resize(file, 1920, 1080);
          this.newImage.type = "image";
          this.newImage.duration = 5000;
          this.overlay.carousel.imageArr.push(this.newImage);
          this.activeImageIndex = this.overlay.carousel.imageArr.length - 1;
          this.showPreview = true;
          this.newImage = {
            title: this.getCampaign.brand.title,
            brandId: this.getCampaign.brand.id,
            imageSrc: "",
            type: "",
            duration: 5000,
          };
        } else {
          this.newImage = null;
        }
      } catch (err) {
        console.error(err);
      }

      this.showBrandModal = false;
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/_colors";
@import "@/styles/_globals";

.stream-overlay__actions {
  display: flex;
  border-bottom: 1px solid color(border, base);
  padding: 0 1.5rem;
  border-radius: $border-radius;
  background-color: color(white, off);
  height: 5rem;
  width: 100%;
  z-index: 1000;
}

.input_video_duration {
  display: flex;
  align-items: center;
  margin-right: auto;
  cursor: no-drop;
  text-align: right;
  padding-left: 2.75rem;
}

.stream-overlay {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: $primary-font-stack;
  place-items: center;

  .title {
    align-items: flex-start;
  }

  .title > h2 {
    font-size: 1.25rem !important;
  }

  .title > h3 {
    font-weight: 500;
    font-size: 1rem !important;
  }

  * {
    font-family: $primary-font-stack !important;
  }
}

.stream-overlay__nav {
  display: flex;
  height: 100vh;
  flex-shrink: 0;
  z-index: 2000;
  width: 5rem;
  background-color: color(blue, darker);
}

.stream-overlay__nav-list {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.stream-overlay__nav-item {
  display: flex;
  height: 5rem;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: color(blue, lighter);

  &--active {
    .button {
      color: color(cta, primary) !important;
    }
  }

  > .button {
    width: 100%;
    height: 100%;
    color: color(blue, lighter) !important;

    &:hover:not(.stream-overlay__nav-item--active) {
      color: color(white, base) !important;
    }
  }
}

.stream-overlay__header {
  display: flex;
  width: calc(100% - 5rem);
  padding: 0 2.5rem;
  z-index: 1000;
  background-color: color(blue, base);
  height: 5rem;
  border-bottom: 1px solid color(border, base);

  .input__label {
    margin: 0 1rem 0 0;
  }
}

.stream-overlay__header-section {
  border-right: 1px solid color(border, base);
  align-items: center;
  display: flex;
  height: 100%;
  padding-right: 2.5rem;
}

.stream-overlay__section {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 1.5rem 2.5rem;
  border-bottom: 1px solid color(border, base);
}

.stream-overlay__sidebar {
  display: flex;
  background-color: color(blue, base);
  z-index: 1000;
  flex-direction: column;
  padding-bottom: 4rem;
  width: 360px;
  //  margin: auto;

  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: color(blue, base);
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.05);
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.15);
  }
}

.stream-overlay__title {
  background-color: transparent !important;
  font-size: 1.5rem !important;
  padding: 0 !important;
  border: 0 !important;
}

.stream-overlay__canvas {
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: 2rem;
  justify-content: center;
}

.stream-overlay__list {
  display: flex;
  flex-direction: column;
}

.stream-overlay__list-item {
  display: flex;
  cursor: grab;
  width: 100%;
  padding: 0.5rem;
  border-radius: $border-radius;
  align-items: center;
  border: 1px solid transparent;

  &--active {
    background-color: color(blue, light);
    border: 1px solid color(cta, secondary);
  }

  .column {
    align-items: flex-start;
  }

  .paragraph {
    line-height: 1;
    margin-bottom: 0.25rem;
  }

  .button--icon {
    margin-left: auto;
  }

  .button {
    color: color(blue, lighter) !important;

    &:hover:not(.stream-overlay__nav-item--active) {
      color: color(white, base) !important;
    }
  }
}

.stream-overlay__list-item-number {
  width: 2rem;
}

.stream-overlay__list-item-img {
  display: flex;
  width: 2rem;
  margin-right: 1rem;
  height: 2rem;
  overflow: hidden;
  border-radius: $border-radius;
  align-items: center;
  justify-content: center;
  border: 1px solid color(border, base);

  > img {
    width: 100%;
    object-fit: cover;
  }
}

.input__file {
  align-items: center;
  height: 100%;
  margin-left: 0 !important;
  border-radius: $border-radius;
  color: color(blue, lighter);
  justify-content: center;
  cursor: pointer;
  flex-direction: column;
  width: 100% !important;
  object-fit: cover;
  display: flex;

  &:hover {
    .input__label {
      color: color(black, base);
    }
  }

  > img {
    width: 100%;
    height: 100%;
  }

  > input {
    position: absolute;
    visibility: hidden;
    opacity: 0;
  }

  .input__label {
    transition: var(--transition-in);
    font-size: 2.5rem;
    color: color(blue, lighter);
    margin-bottom: 0 !important;
  }
}

.stream-overlay .modal__inner {
  padding: 4rem;
  border-radius: $border-radius;
  background-color: color(blue, base);
}

.vue-ruler-v {
  height: 100vh !important;
}
</style>
