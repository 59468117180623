export default class Image {
  static base64encode(image) {
    return new Promise((resolve, reject) => {
      if (image) {
        const fr = new FileReader();
        fr.addEventListener("load", (e) => resolve(e.target.result));
        fr.readAsDataURL(image);
      } else {
        reject("no image");
      }
    });
  }

  static resize(file, MAX_WIDTH = 200, MAX_HEIGHT = 200, QUALITY = 1.0) {
    return new Promise((resolve, reject) => {
      if (window.File && window.FileReader && window.FileList && window.Blob) {
        if (file) {
          const reader = new FileReader();
          reader.onload = (e) => {
            // todo: maybe use shadow dom instead of appending the image to the dom..
            const img = document.createElement("img");
            img.style = "position: absolute; top: -1000vh; left: -1000vw; opacity: 0;";
            document.getElementsByTagName("body")[0].appendChild(img);
            img.src = e.target.result;
            img.onload = () => {
              const canvas = document.createElement("canvas");
              const ctx = canvas.getContext("2d");

              let width = img.width;
              let height = img.height;

              if (width > height) {
                if (width > MAX_WIDTH) {
                  height *= MAX_WIDTH / width;
                  width = MAX_WIDTH;
                }
              } else if (height > MAX_HEIGHT) {
                width *= MAX_HEIGHT / height;
                height = MAX_HEIGHT;
              }

              canvas.width = width;
              canvas.height = height;
              ctx.drawImage(img, 0, 0, width, height);

              const base64 = canvas.toDataURL(file.type, QUALITY);
              resolve(base64);

              img.remove();
            };
          };
          reader.readAsDataURL(file);
        } else {
          reject("no selected file");
        }
      } else {
        reject("The File APIs are not fully supported in this browser.");
      }
    });
  }
}
